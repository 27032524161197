.link {
  display: inline;
  color: var(--colorBlack);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.link:hover {
  color: var(--colorBlack);
  text-decoration: underline;
}

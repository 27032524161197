@import '../../../../styles/customMediaQueries.css';

.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.searchBox {
  display: flex;
  background-color: transparent;
  border: 4px solid var(--marketplaceColor);
  border-radius: 16px;
  height: 75px;
}

.searchBoxItem {
  position: relative;
  flex: 1;
  border-right: 1px solid var(--colorGrey100);
  height: 67px;
  border-radius: 16px;
}

.searchBoxItemOutsideClick {
  width: 100%;
}

.searchBoxItemContent {
  display: flex;
  position: relative;
  align-items: center;
  padding: 23px 32px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
  height: 67px;
  border-radius: 16px;

  & svg {
    margin-right: 10px;
    width: 26px;
  }
}

.searchBoxItemInput {
  position: absolute;
  right: 20px;
  left: 50px;
  top: 10px;
  bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.suggestionPanel {
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;

  & ul {
    margin: 0;

    & li {
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2 linear;

      &:hover {
        background-color: var(--colorGrey800);
      }
    }
  }
}

.locationAutocompletePanel {
  height: 30px;
  flex-grow: 1;
}

.locationAutocomplete {
  height: 30px;
}

.locationAutocompleteIcon {
  display: none;
}

.locationAutocompleteInput {
  background-color: transparent;
  border-radius: 0;
  border: 0px solid transparent;
  border-bottom: 0px solid transparent;
  outline: none;
  padding: 0;
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    font-size: 30px;
  }

  &:hover {
    border: 0px solid transparent;
    border-bottom: 0px solid transparent;
  }

  &:focus {
    background-color: transparent;
    border-bottom: 0px solid transparent;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 30px;
    font-weight: 500;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
      font-size: 30px;
    }
  }
}

.locationAutocompletePredict {
  position: absolute;
  top: 45px;
  left: -47px;
  background-color: rgb(0, 0, 0);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  width: calc(100vw - 64px);

  @media (--viewportMedium) {
    right: -20px;
    width: 300px;
  }
}

.searchSubmit {
  flex: 0.4;
  padding: 5px 15px;
  min-height: 44px;
  border-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #D7FF5A;
  color: var(--colorBlack);

  &:hover {
    background-color: #bfff00;
  }
}